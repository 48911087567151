

// 发票类型
export const invoceType = {
  // 1: "未开票",
  // 2: "已开票",
  1: "待审核",
  2: "已审核",
  4: "开票中",
  5: "开票失败",
  6: "开票成功",
};

export function formatInvoceType(val) {
  return invoceType[val];
}

// 网点类型
export const outletsType = {
  1:'门店',
  2:'配送站'
}
export function formatOutletsType (val) {
  return outletsType[val]
}
export const outletsTheme = {
  "default": '常规店',
  "lab": 'Lab',
  "select": 'Select',
  "market": 'Market',
  "pink": 'Pink',
  "travel": 'Travel',
}
export function formatOutletsTheme (val) {
  return outletsTheme[val]
}

export const businessStatus = {
  1:'已关店',
  2:'未发布',
  3:'营业中'
}
export function formatBusinessStatus (val) {
  return businessStatus[val]
}

// 商品类型
export const goodsType = {
  1: "预包装类", // 软点
  2: "现作现烤类", // 西点
  3: "蛋糕",
  4: "好茶",
}
export function formatGoodsType(val) {
  return goodsType[val];
}

// debug 好茶
// 好茶属性
// 杯型：大杯（约550ml）
// 温度：标准冰 /  少冰 （推荐）/ 去冰 / 热
// 甜度：标准糖（推荐）/ 少糖 / 少少糖 / 不另外加糖（不推荐）
// 其他：专用三品管 / 不使用吸管
// 产品成分：0奶精 0香精 0植脂末 0反式脂肪酸
// 好茶属性-温度
export const drinkAttrTemp = [
  {
    code: "temp_ice",
    name: "标准冰",
  },{
    code: "temp_lessice",
    name: "少冰",
  },{
    code: "temp_noice",
    name: "去冰",
  },{
    code: "temp_hot",
    name: "热",
  },
]
// 好茶属性-甜度
export const drinkAttrSugar = [
  {
    code: "sugar_normal",
    name: "标准糖",
  },{
    code: "sugar_less",
    name: "少糖",
  },{
    code: "sugar_bit",
    name: "少少糖",
  },{
    code: "sugar_no",
    name: "不另外加糖",
  },
]
// 好茶属性-吸管
export const drinkAttrStraw = [
  {
    code: "straw_1",
    name: "专用三品管",
  },{
    code: "straw_no",
    name: "不使用吸管",
  },
]

// 商品售卖模式
export const saleModel = {
  0: "正常", 
  1: "预约", 
  2: "预售",
  3: "限量预售",
}
export function formatSaleModel(val) {
  return val ? saleModel[val] : "正常";
}

// 优惠券使用类型
export const couponType = {
  1: "未领取",
  2: "未使用",
  3: "已使用",
  4: "已过期",
};

// //退款状态 （1-未退款，2-退款中，3-已退款，4-退款失败）
// export const refundStatus = {
//   1: { desc: "申请退款", title: "申请退款" },
//   2: { desc: "审核中", title: "退款中" },
//   3: { desc: "退款成功", title: "已退款" },
//   4: { desc: "退款失败", title: "退款失败" },
//   4: { desc: "退款失败", title: "退款失败" },
// };


// 第三方订单状态
export const otherOrderStatusList = [
  { id: 11, name: "未建单-待建单" },
  { id: 12, name: "未建单-已关闭" },
  { id: 13, name: "未建单-已忽略" },
  { id: 21, name: "已建单-未完成" },
  { id: 22, name: "已建单-已取消" },
  { id: 23, name: "已建单-已完成" },
  { id: 24, name: "已建单-已售后" },
];
export function formatOtherOrderStatus(id) {
  if(!id) return ''
  return otherOrderStatusList.find((i) => i.id == id).name || id;
}
// 第三方订单来源
export const otherOrderSourceList = [
  { id: 22, name: "京东" },
  { id: 23, name: "天猫" },
  { id: 24, name: "抖音" },
  { id: 25, name: "小红书" },
  // { id: 26, name: "京东自营" },
  { id: 27, name: "抖音来客" },
  { id: 21, name: "饿了么" },
  { id: 20, name: "美团外卖" },
  { id: 28, name: "美团团购" },
];
export function formatOtherOrderSource(id) {
  return otherOrderSourceList.find((i) => i.id === id).name || id;
}
export function isTakeawayPlat(order_source_id) {
  return [20,21,27].includes(parseInt(order_source_id))
}


// 订单状态
export const orderStatusList = [
  // { id: 0, name: "全部" },
  { id: -1, name: "已取消" },
  { id: 1, name: "未支付" },
  { id: 2, name: "自提审核" },
  { id: 3, name: "配送审核" },
  { id: -2, name: "修改审核" },
  { id: 4, name: "已分派" },
  { id: 5, name: "制作中" },
  { id: 6, name: "备货完成" },
  { id: 7, name: "配送中" },
  { id: 8, name: "快递中" },
  { id: 9, name: "已完成" },
];
export function formatOrderStatus(id) {
  return orderStatusList.find((i) => i.id === id).name || id;
}

// 新订单状态
export const orderStatusList2 = [
  // { id: 0, name: "全部" },
  { id: -1, name: "已取消" },
  { id: 1, name: "待付款" },
  { id: 2, name: "待审核自提" },
  { id: 3, name: "待审核配送" },
  { id: 4, name: "已分配" },
  { id: 5, name: "制作中" },
  { id: 6, name: "备货完成" },
  { id: 7, name: "配送中" },
  // { id: 8, name: "快递单" },
  { id: 9, name: "已完成" },
];
export function formatOrderStatus2(id) {
  return orderStatusList2.find((i) => i.id === id).name || id;
}


export const cakeOrderStatusList = [
  { id: 0,  name: "全部" },
  { id: 2, name: "自提审核" },
  { id: 3, name: "配送审核" },
  { id: -2, name: "修改审核" },
  { id: 4, name: "已分派" },
  // { id: 5,  name: "制作中" },
  // { id: 6,  name: "备货完成" },
  // { id: 7,  name: "配送中" },
  { id: 9, name: "已完成" },
  { id: -1, name: "已取消" },
];

// 订单状态 订单状态 （-2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成）
export const orderDetailStatusOptions = [
  { id: -2, name: "已取消（旺店通需自行处理）" },
  { id: -1, name: "已取消" },
  { id: 1, name: "未支付" },
  { id: 2, name: "未审核" },
  { id: 3, name: "已分派" },
  { id: 4, name: "已完成" },
];
export function formatDetailOrderStatus(id) {
  if (id) {
    return orderDetailStatusOptions.find((i) => i.id === id).name || id;
  } else {
    return id;
  }
}


// 订单状态：内部状态
const _orderInnerStatusList = [
  { id: -2, name: "已取消（旺店通需自行处理）" },
  { id: -1, name: "已取消" },
  { id: 1, name: "待付款" },
  { id: 2, name: "未审核" },
  { id: 3, name: "已分配", subItem: {
      1: "待自提",
      2: "待接单",
      3: "待发货"
  }},
  { id: 5, name: "备货中", subItem: {
      1: "待自提",
      2: "待接单",
      3: "待发货"
  }},
  { id: 10, name: "备货完成", subItem: {
      1: "待自提",
      2: "待接单",
      3: "待发货"
  }},
  { id: 13, name: "已接单" },
  { id: 20, name: "配送中", subItem: {
      1: "待自提",
      2: "配送中",
      3: "已发货",
  }},
  { id: 23, name: "已挂起" },
  { id: 30, name: "已完成", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
  { id: 40, name: "退款已申请", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
  { id: 41, name: "退款已审核", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
  { id: 42, name: "部分已退款", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
  { id: 44, name: "全部已退款", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
];
export function formatInnerOrderStatus(status, order_type){
  let item = _orderInnerStatusList.find((i) => i.id === status)
  if(item){
    if(order_type && item.subItem){
      order_type = order_type==4 ? 2 : order_type
      if(item.subItem[order_type]) return item.subItem[order_type]
    }
  }
  return item ? item.name : "";
}

// 订单状态：用户端状态
const _orderUserStatusList = [
  { id: -2, name: "已取消（旺店通需自行处理）" },
  { id: -1, name: "已取消" },
  { id: 1, name: "待付款" },
  { id: 2, name: "未审核", subItem: {
      1: "待自提",
      2: "待配送",
      3: "待发货"
  }},
  { id: 3, name: "已分配", subItem: {
      1: "待自提",
      2: "待配送",
      3: "待发货"
  }},
  { id: 5, name: "备货中", subItem: {
      1: "待自提",
      2: "待配送",
      3: "待发货"
  }},
  { id: 10, name: "备货完成", subItem: {
      1: "待自提",
      2: "待配送",
      3: "待发货"
  }},
  { id: 13, name: "待配送" },
  // 自提超时
  { id: 20, name: "配送中", subItem: {
      1: "待自提",
      2: "配送中",
      3: "已发货",
  }},
  { id: 23, name: "配送中" },
  { id: 30, name: "已完成", subItem: {
      1: "已自提",
      2: "已送达",
      3: "已收货"
  }},
  { id: 40, name: "退款已申请" },
  // { id: 41, name: "退款已审核" },
  { id: 41, name: "退款已申请" },
  { id: 42, name: "部分已退款" },
  { id: 44, name: "整单已退款" },
];
export function formatUserOrderStatus(status, order_type){
  let item = _orderUserStatusList.find((i) => i.id === status)
  if(item){
    if(order_type && item.subItem){
      order_type = order_type==4 ? 2 : order_type
      if(item.subItem[order_type]) return item.subItem[order_type]
    }
  }
  return item ? item.name : "";
}

// 订单状态：用户端状态
const _orderRefundStatusList = [
  { id: 40, name: "退款待审核" },
  { id: 41, name: "待财务退款" },
  { id: 42, name: "部分已退款" },
  { id: 44, name: "整单已退款" },
];
export function formatOrderRefundStatus(status){
  let item = _orderRefundStatusList.find((i) => i.id === status)
  return item ? item.name : "";
}

// 订单预警
const _warningTypeOptions = {
  1: "未接单提醒",
  2: "配送超时",
  3: "紧急取消",
}
export function formatWarningType(id) {
  return _warningTypeOptions[id]
}

// 网点子订单状态
const pdSubOrderStatusList = [
  { id: -1, name: "已取消" },
  { id: 1, name: "未分派" },
  { id: 2, name: "已分派" },
  { id: 3, name: "制作中" },
  { id: 4, name: "备货完成" },
  { id: 5, name: "配送中" },
  { id: 6, name: "已完成" },
];
// 快递子订单状态
const epSubOrderStatusList = [
  { id: -2, name: "已取消-旺店通需自行处理" },
  { id: -1, name: "已取消" },
  { id: 1, name: "未分派" },
  { id: 2, name: "已分派" },
  { id: 3, name: "快递配送" },
  { id: 4, name: "已完成" },
];

// 订单是否修改状态
export const orderIsModifyList = [
  { value: 1, label: "用户" },
  { value: 2, label: "客服" },
  { value: 3, label: "未修改" },
];

// 订单类型
export const orderTypeList = [
  // { id: 0, name: "全部" },
  { id: 1, name: "自提" },
  { id: 2, name: "配送" },
  { id: 3, name: "快递" },
];

export function formatOrderType(id) {
  return orderTypeList.find((i) => i.id === id).name;
}

// 支付方式0-全部，1-会员支付，2-微信支付，3-支付宝支付，10-货到付款
export const payTypeList = [
  // { id: 0, name: "全部" },
  { id: 1, name: "会员支付" },
  { id: 2, name: "微信" },
  { id: 3, name: "支付宝" },
  { id: 10, name: "货到付款" },
  { id: 20, name: "其他" },
  { id: 30, name: "挂账" },
];

// 支付方式：货到付款的细分
export const subCODPayTypeList = [
  // { id: 0, name: "无" },
  { id: 1, name: "刷卡" },
  { id: 2, name: "现金" },
  { id: 3, name: "刷提货卡" },
  { id: 4, name: "微/支" },
];

// 支付方式：挂账的细分
export const subOnAccountPayTypeList = [
  { id: 13, name: "挂账(天猫)" },
  { id: 11, name: "挂账(京东)" },
  { id: 10, name: "挂账(京东自营)" },
  // { id: 12, name: "挂账(美团)" },
  // { id: 14, name: "挂账(悦才)" },
];

// 门店在售切换标签
export const goodsStatusList = [
  { id: 0, name: "全部在售" },
  { id: 1, name: "非在售" },
  { id: 3, name: "小程序门店在售" },
  { id: 2, name: "小程序商城在售" },
  { id: 4, name: "官网在售" },
  { id: 5, name: "回收站" },
];

// 订单来源
export const orderSourceList = [
  // { id: 0, name: "全部" },
  { id: 1, name: "小程序快递" },
  { id: 2, name: "小程序门店" },
  { id: 3, name: "PC官网" },
  { id: 4, name: "手机官网" },

  { id: 15, name: "网点下单" },
  { id: 10, name: "客服下单" },
  { id: 16, name: "收银下单" },
  
  { id: 22, name: "京东" },
  { id: 23, name: "天猫" },
  { id: 27, name: "抖音来客", isTakeaway: true },
  { id: 21, name: "饿了么", isTakeaway: true },
  { id: 20, name: "美团外卖", isTakeaway: true },
  { id: 28, name: "美团团购", isTakeaway: true },
];
export function formatOrderSource(id) {
  if (!id) return "";
  return orderSourceList.find((i) => i.id === id).name;
}

// 快递订单来源
export const expressOrderSourceList = [
  { id: 23, name: "天猫" },
  { id: 22, name: "京东" },
  { id: 26, name: "京东自营" },
  { id: 24, name: "抖音" },
  { id: 25, name: "小红书" },
];
export function formatExpressOrderSource(id) {
  if (!id) return "";
  const item = expressOrderSourceList.find((i) => i.id === id)
  return item ? item.name : id;
}

export function formatPayType(id) {
  return orderTypeList.find((i) => i.id === id).name;
}

export function formatPdSubOrderStatus(id) {
  return pdSubOrderStatusList.find((i) => i.id === id).name;
}

export function formatEpSubOrderStatus(id) {
  return epSubOrderStatusList.find((i) => i.id === id).name;
}

export const cardList = [
  "生日快乐",
  "Happy Birthday!",
  "不管几岁 快乐万岁",
  "永远18岁",
  "I Love U！",
  "将至美，给至爱",
  "天天快乐",
  "节日快乐",
  "合家团园",
  "健康快乐",
  "寿与天齐",
  "健康长寿",
  "福如东海，寿比南山",
]

// 餐具数量
export const tableWare = [
  0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 
  30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 
  60, 63, 66, 69, 72, 75, 78, 81, 84, 87, 90,
]

// 生日蜡烛
export const candleOptions = [
  {
    value: "1",
    label: "数字蜡烛",
    children: getNumberList(),
  },
  {
    value: "2",
    label: "线蜡烛",
    children: [
      {
        value: "1",
        label: "一盒",
      },
    ],
  },
];
// 生日蛋糕蜡烛
export const birthdayCandle = {
  1: "数字蜡烛",
  2: "线蜡烛",
};

export function formatBirthdayCandle (infos) {
  if(infos){
    const arr = infos.split(":");
    if(arr.length >2){
      return birthdayCandle[arr[0]] + " " + arr[1] + arr[2] + arr[3]
    }else{
      return birthdayCandle[arr[0]] + " " + (arr[0] == 1 ? arr[1] : "一盒");
    }
  }else{
    return '无'
  }
 
}
// 生日蛋糕蜡烛 end

// 自提时间，根据当前时间和店铺营业结束时间计算所得
export function pickupTime(endTime, startTime) {
  return getPickUpTime(startTime, endTime);
}

/**
 * 根据当前时间+营业时间计算
 * @param {*} length
 */
function getPickUpTime(startTime, endTime) {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  if (startTime) {
    var start = startTime.split(":");
    if (start.length != 2) {
      return false;
    }
    now.setHours(start[0]);
    now.setMinutes(start[1]);
    hours = start[0];
    minutes = start[1];
  }

  var stre = endTime.split(":");
  if (stre.length != 2) {
    return false;
  }

  var end = new Date();
  end.setHours(stre[0]);
  end.setMinutes(stre[1]);

  let arr = [],
    startHours = Number(hours),
    startMinutes = "00";

  while (startHours < Number(stre[0])) {
    if (minutes >= 30) {
      const item = startHours + 1 + ":" + startMinutes;
      if (startHours + 1 != Number(stre[0])) {
        arr.push(
          {
            name: item + "-" + (startHours + 1) + ":" + "30",
          },
          {
            name:
              startHours + 1 + ":" + "30" + "-" + (startHours + 2) + ":" + "00",
          }
        );
      }
    } else {
      const item = startHours + ":" + "30";
      arr.push({
        name: item + "-" + (startHours + 1) + ":" + "00",
      });
    }
    startHours++;
  }
  return arr;
}

function getNumberList() {
  let list = [];
  for (let i = 1; i < 102; i++) {
    list.push({
      label: i,
      value: i.toString(),
    });
  }
  return list;
}

// ====== 发短信

export const smsConfig = [
  {
    type: 1,
    typeName: "门店信息",
    content: "【好利来】尊敬的顾客，{$shop_name}的地址为：{$shop_addr}，联系电话为：{$shop_tel}",
  },{
    type: 2,
    typeName: "无法取得联系",
    content: "【好利来】您好!尊敬的顾客由于我们无法与您提供的联系方式取得联系,请您收到信息后尽快与我们联系,400-700-5999.祝您生活愉快！",
  },{
    type: 3,
    typeName: "支付宝支付",
    content: "【好利来】好利来信息：订单号为：{$order_no}，支付宝账号为：****，金额为：{$amount}元，支付宝账户名称：****。请把订单号填写在备注，有问题请联系4007005999，祝您生活愉快！",
  },{
    type: 4,
    typeName: "配送发货通知",
    content: "【好利来】订单已成功受理，单号：{$order_no}，商品：{$goods}，收货时间：{$dlvr_time}，收货地址：{$dlvr_addr}，如有疑问请致电4007005999。",
  },{
    type: 5,
    typeName: "送货费提醒",
    content: "【好利来】尊敬的顾客您好，您在{$source}上订购的蛋糕需要{$freight}元配送费 ，请您看到短信后尽快联系我们，联系电话：400-700-5999",
  },{
    type: 6,
    typeName: "订单通知短信",
    content: "【好利来】尊敬的顾客您好，您在{$source}订购的产品订单暂未支付成功，为避免耽误您的食用时间，请尽快完成支付，如需取消请致电400-700-5999！",
  },{
    type: 7,
    typeName: "自定义模板",
    content: "【好利来】xxxx，您好xxxx。",
  },
]

// 短信类型
export function formatSmsType (type) {
  let item = smsConfig.find(el=>el.type == type)
  return item ? item.typeName : type
}