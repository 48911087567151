import ajax from "@/utils/ajax.js";

/****************************全部订单******************************/
/**
 * 列表
 * @param {*} params
 * @returns
 */
export function getAllOrderList(params) {
  return ajax.post("/CustomerServiceAllOrder/getList", params);
}

/**
 * 查询负责网点列表
 * @param {*} params
 * @returns
 */
 export function getOutletsList(params) {
  return ajax.post("/CustomerServiceCommonApi/searchOutlets", params);
}

/**
 * 查询员工列表
 * @param {*} params
 * @returns
 */
 export function getStaffList(params) {
  return ajax.post("/CustomerServiceCommonApi/searchStaff", params);
}
/**
 * 详情
 * @param {*} params
 * @returns
 */
export function getAllOrderDetail(params) {
  return ajax.post("/CustomerServiceAllOrder/detail", params);
}

/**
 * 审核 更换配送站：配送网点列表
 * @param {*} params
 * @returns
 */
export function getDeliveryOutletsList(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getDeliveryOutletsList", params);
}

/**
 * 审核 更换门店：门店列表
 * @param {*} params
 * @returns
 */
export function getPickupShopsList(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getPickUpOutletsList", params);
}

/****************************蛋糕订单******************************/
/**
 * 列表
 * @param {*} params
 * @returns
 */
export function getCakeOrderList(params) {
  return ajax.post("/CustomerServiceCakeOrder/getList", params);
}

/**
 * 详情
 * @param {*} params
 * @returns
 */
export function getCakeOrderDetail(params) {
  return ajax.post("/CustomerServiceCakeOrder/getInfo", params);
}

/**
 * 取消订单
 * @param {*} params
 * @returns
 */
export function cancelCakeOrder(params) {
  return ajax.post("/CustomerServiceCakeOrder/cancel", params);
}

/**
 * 审核修改
 * @param {*} params
 * @returns
 */
export function changeAuditCakeOrder(params) {
  return ajax.post("/CustomerServiceCakeOrder/auditUpdate", params);
}

/**
 * 自提配送互转
 * @param {*} params
 * @returns
 */
export function transformSuborderType(params) {
  return ajax.post("/CustomerServiceCakeOrder/updateSuborderType", params);
}


/****************************无蛋糕订单******************************/
/**
 * 列表
 * @param {*} params
 * @returns
 */
export function getNotCakeOrderList(params) {
  return ajax.post("/CustomerServiceNotCakeOrder/getList", params);
}

/**
 * 详情
 * @param {*} params
 * @returns
 */
export function getNotCakeOrderDetail(params) {
  return ajax.post("/CustomerServiceNotCakeOrder/detail", params);
}

/**
 * 取消订单
 * @param {*} params
 * @returns
 */
export function notCakeCancelOrder(params) {
  return ajax.post("/CustomerServiceNotCakeOrder/cancel", params);
}

/****************************其他******************************/
/**
 * 获取配送员信息
 * @param {*} params
 * @returns
 */
export function getCourierInfo(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getCourierInfo", params);
}

/**
 * 获取快递物流信息
 * @param {*} params
 * @returns
 */
export function getExpressInfo(params) {
  return ajax.post("/CustomerServiceAllOrderOther/getLogisticsInfo", params);
}

/**
 * 获取配送网点没有库存的商品信息（配送）
 * @param {*} params
 * @returns
 */
 export function getOutletsNotStockGoodsList(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getDeliveryOutletsNotStockGoodsList", params);
}

/**
 * 获取配送网点没有库存的商品信息（自提）
 * @param {*} params
 * @returns
 */
 export function getShopNotStockGoodsList(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getPickUpOutletsNotStockGoodsList", params);
}


/**
 * 获取用户修改信息
 * @param {*} params
 * @returns
 */
 export function getUserUpdateInfo(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getUserUpdateInfo", params);
}

/**
 * 用户信息修改审核通过
 * @param {*} params
 * @returns
 */
 export function userUpdateInfoPass(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/UserUpdateInfoAuditPassed", params);
}


/**
 * 用户信息修改审核通过
 * @param {*} params
 * @returns
 */
 export function getSplitOrderGoodsList(params) {
  return ajax.post("/CustomerServiceCakeOrderOther/getSplitOrderGoodsList", params);
}

/**
 * 修改订单的客服备注
 * @param {*} params
 * @returns
 */
export function updateCustomerRemarks(params) {
  return ajax.post("/CustomerServiceAllOrderOther/updateCustomerServiceRemarks", params);
}

/**
 * 修改订单的内部备注
 * @param {*} params
 * @returns
 */
export function updatePrivateRemarks(params) {
  return ajax.post("/CustomerServiceAllOrderOther/updatePrivateRemarks", params);
}

/**
 * 订单流水
 * @param {*} params
 * @returns
 */
export function getAllOrderFlowLog(params) {
  return ajax.post("/CustomerServiceAllOrder/getFlowLog", params);
}


/**
 * 操作日志
 * @param {*} params
 * @returns
 */
export function getAllOrderOptLog(params) {
  return ajax.post("/CustomerServiceAllOrder/getOperationLog", params);
}

/**
 * 售后开关
 * @param {*} params
 * @returns
 */
export function updateRefundSwitch(params) {
  return ajax.post("/CustomerServiceAllOrderOther/updateRefundSwitch", params);
}


// ====================== 发短信

/**
 * 短信log
 * @param {*} params
 * @returns
 */
export function getOrderSmsLogList(params) {
  return ajax.post("/CustomerServiceAllOrderOther/getOrderSendSmsLogs", params);
}

/**
 * 获取短信默认模板
 * @param {*} params
 * @returns
 */
export function getOrderSmsTmp(params) {
  return ajax.post("/CustomerServiceAllOrderOther/getOrderSendSmsContent", params);
}

/**
 * 发短信
 * @param {*} params
 * @returns
 */
export function sendOrderSms(params) {
  return ajax.post("/CustomerServiceAllOrderOther/orderSendSms", params);
}



/**
 * 全部订单列表
 * @param {*} params
 * @returns
 */
 export function getAllList(params) {
  return ajax.post("/CustomerServiceAllOrder/getAllList", params);
}

/**
 * 其他订单列表
 * @param {*} params
 * @returns
 */
 export function getOtherList(params) {
  return ajax.post("/CustomerServiceAllOrder/getOtherList", params);
}


// ====================== 第三方订单

/**
 * 全部第三方订单列表
 * @param {*} params
 * @returns
 */
 export function otherOrderGetAllList(params) {
  return ajax.post("/CustomerServiceOtherOrder/getAllList", params);
}

/**
 * 其他第三方订单列表
 * @param {*} params
 * @returns
 */
 export function otherOrderGetOtherList(params) {
  return ajax.post("/CustomerServiceOtherOrder/getOtherList", params);
}

/**
 * 第三方订单详情
 * @param {*} params
 * @returns
 */
 export function otherOrderGetDetail(params) {
  return ajax.post("/CustomerServiceOtherOrder/detail", params);
}

/**
 * 添加第三方订单流水
 * @param {*} params
 * @returns
 */
 export function otherOrderAddFlow(params) {
  return ajax.post("/CustomerServiceOtherOrder/addFlow", params);
}

/**
 * 第三方订单流水记录
 * @param {*} params
 * @returns
 */
 export function otherOrderGetFlowLog(params) {
  return ajax.post("/CustomerServiceOtherOrder/getFlowLog", params);
}

/**
 * 同步第三方订单平台发货
 * @param {*} params
 * @returns
 */
 export function otherOrderSyncDelivery(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/syncOtherPlatformDelivery", params);
}

/**
 * 不处理第三方订单
 * @param {*} params
 * @returns
 */
 export function otherOrderIgnore(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/ignore", params);
}

/**
 * 第三方订单修改：标记已处理
 * @param {*} params
 * @returns
 */
 export function otherOrderProcessed(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/handledUpdate", params);
}

/**
 * 继续处理第三方订单
 * @param {*} params
 * @returns
 */
 export function otherOrderContinue(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/continueUse", params);
}

/**
 * 取消第三方订单
 * @param {*} params
 * @returns
 */
 export function otherOrderCancel(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/cancel", params);
}

/**
 * 修改第三方订单商品快递发货标识
 * @param {*} params
 * @returns
 */
 export function updateOtherOrderGoodsExpressStatus(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/updateOtherOrderGoodsExpressStatus", params);
}

/**
 * 第三方订单申请退款
 * @param {*} params
 * @returns
 */
 export function otherOrderApplyRefund(params) {
  return ajax.post("/CustomerServiceOtherOrder/applyRefund", params);
}


/**
 * 编辑第三方订单
 * @param {*} params
 * @returns
 */
 export function otherOrderEdit(params) {
  return ajax.post("/CustomerServiceOtherOrder/edit", params);
}



/**
 * 未完成的快递单列表
 * @param {*} params
 * @returns
 */
export function expressPendingList(params) {
  return ajax.post("/CustomerServiceExpressOrder/getNotFinishSuborderList", params);
}

/**
 * 检查未完成快递单（尝试完成）
 * @param {*} params
 * @returns
 */
export function tryFinishExpressOrder(params) {
  return ajax.post("/CustomerServiceExpressOrder/finishSuborder", params);
}

