<template>
  <div>
    <a-modal :visible.sync="show"
            width="950px"
            :title="isAudit ? '发票审核' : '上传发票' "
            @cancel="$emit('update:show', false)">
      <div class="header">
        <h4>订单支付:</h4>
        <span v-if="Number(invoiceData.vip_card_pay_amount)>0">钱包 {{ invoiceData.vip_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.stored_value_card_pay_amount)>0">实体卡 {{ invoiceData.stored_value_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.wx_pay_amount)>0">微信 {{ invoiceData.wx_pay_amount }}元</span>
        <span v-if="Number(invoiceData.alipay_amount)>0">支付宝 {{ invoiceData.alipay_amount }}元</span>
        <span v-if="Number(invoiceData.cash_delivery_amount)>0">货到付款 {{ invoiceData.cash_delivery_amount }}元</span>
      </div>

      <base-table
        ref="invoiceRef"
        rowKey="invoiceTableRowKey"
        :columnsData="columns_invoice"
        :tableData="invoiceTableData"
        :customHeight="200"
      ></base-table>

      <!-- 旺店通账户 分仓库 -->
      <template v-if="expressTableData && expressTableData.length">
        <h5 class="mt-3" style="color:#f00;">* 此订单的旺店通子订单被拆分为多仓库发货，所以需要人工分仓库开票</h5>
        <base-table
          :columnsData="columns_express"
          :tableData="expressTableData"></base-table>
      </template>

      <div class="footer">
        <h3 class="mt-3 title-bg">用户申请信息</h3>
        <a-form-model
          layout="horizontal"
          :model="invoiceInfo"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          :rules="rules"
        >
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="发票金额">{{ invoiceInfo.open_amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                prop="email" label="邮箱">
                <a-input v-model="invoiceInfo.email"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" label="税号">
                  <a-input v-model="invoiceInfo.corporate_duty_paragraph"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="6">
                <a-form-model-item label="电话">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="开户行">
                  <a-input v-model="invoiceInfo.open_bank"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="账号">
                  <a-input v-model="invoiceInfo.bank_account"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" :wrapperCol="{span:22}" label="注册地址">
                  <a-input v-model="invoiceInfo.registered_address"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="电话" prop="phone">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 开票失败记录 -->
          <template v-if="invoiceData.open_fail_log && invoiceData.open_fail_log.length">
            <h3 class="mt-3 title-bg">开票失败记录</h3>
            <base-table class="mb-2"
              :columnsData="columns_fail_log"
              :tableData="invoiceData.open_fail_log"
            ></base-table>
          </template>

          <!-- 上传发票 / 备注 -->
          <h3 class="mt-3 title-bg">财务开票处理</h3>
          <a-row class="mt-2" v-if="isOpen">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" 
                label="上传发票" class="upload-class">
                <uploadImage type="20" multiple :value.sync="invoiceInfo.invoice_files" @getList="(list)=>{invoiceInfo.invoice_files = list}" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item v-if="isAudit" :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="审核备注">
                <a-input type="textareas" v-model="invoiceData.audit_remarks"></a-input>
              </a-form-model-item>
              <a-form-model-item v-if="isOpen" :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="开票备注">
                <a-input type="textareas" v-model="invoiceData.open_remarks"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>

      <template slot="footer">
        <template v-if="isAudit">
          <a-button class="mr-4" @click="$emit('update:show', false)">取消</a-button>
          <a-button type="primary" @click="handlerAudit">审核通过（系统会自动开票）</a-button>
        </template>
        <template v-if="isOpen">
          <a-button class="mr-4" @click="$emit('update:show', false)">取消</a-button>
          <a-button type="primary" @click="handlerOpen">提交发票（发送到用户邮箱）</a-button>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import pageData from "../columns"
import uploadImage from "@/components/upload-image"
import {
  getInvoiceApplyDesc,
  auditInvoce,
  openInvoce,
} from "@/api/invoice/order-invoice.js"
export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: Number,
    },
    type: {
      type: String,
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    // let phoneCheck = (rule, value, callback) => {
    //   const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    //   if (!reg.test(value)) {
    //     callback("手机号码格式有误")
    //     return
    //   } else {
    //     callback()
    //   }
    // }
    let emailCheck = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (!reg.test(value)) {
        callback("邮箱格式有误")
        return
      } else {
        callback()
      }
    }
    return {
      ...pageData,
      rules: {
        // phone: [{ validator: phoneCheck, trigger: "blur" }],
        email: [{ validator: emailCheck, trigger: "blur" }],
      },
      columns_fail_log: [
        {
          title: "失败时间",
          dataIndex: "fail_time",
          align: "center",
          width: 180,
        },
        {
          title: "失败原因",
          dataIndex: "fail_reason",
          align: "center",
        },
      ],

      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      invoiceTableData: [],
      invoiceInfo: {},
      expressTableData: [],

      isAudit: false,
      isOpen: false,
    }
  },
  mounted() {
    this.isAudit = this.type == "audit"
    this.isOpen = this.type == "open"

    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getInvoiceApplyDesc({ id: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.goods_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.expressTableData = data.wdt_order_list
        this.invoiceInfo = data.invoice_info
      }
    },

    handlerAudit() {
      const hasRunTimeFailed = (this.invoiceData.open_fail_log || []).some(el=>el.fail_status==2)
      if(hasRunTimeFailed){
        const _this = this
        this.$confirm({
          title: "提示",
          content: "此发票是开票中失败，建议找发票服务商沟通解决、不建议审核重开",
          okText: "已沟通过、确定审核重开",
          okType: "danger",
          cancelText: "取消",
          async onOk() {
            _this.handlerAuditOK()
          },
          onCancel() {
            console.log("Cancel")
          },
        })
      }else{
        this.handlerAuditOK()
      } 
    },
    handlerAuditOK(){
      const params = this.invoiceInfo
            params.id = this.requestID
            params.audit_remarks = this.invoiceData.audit_remarks
      auditInvoce(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("已审核")
          this.$emit("update:show", false)
        }
      })
    },
    handlerOpen() {
      // 电子票 必须传图
      if(!this.invoiceInfo.invoice_files.length){
        this.$message.warning("请上传电子发票")
        return
      }
      const params = this.invoiceInfo
            params.id = this.requestID
            params.file_paths = params.invoice_files.join(",")
            params.open_remarks = this.invoiceData.open_remarks
      openInvoce(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("开票成功")
          this.$emit("update:show", false)
        }
      })
    },
    handlerCancel() {
      this.$emit("update:show", false)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}
.footer {
  margin-top: 20px;
  .img-class {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }
}
.ant-form-item {
  margin-bottom: 0;
}

/deep/ .upload-class .ant-upload-picture-card-wrapper{
  margin-top:10px;
}
</style>